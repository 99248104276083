body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}

.tg .tg-cly1 {
  text-align: left;
  vertical-align: middle;
}

.tg .tg-lboi {
  border-color: inherit;
  text-align: left;
  vertical-align: middle;
  font-family: sans-serif;
}

.tg .tg-uwqm {
  background-color: #f7904c;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  font-family: sans-serif;
}

.tg .tg-9wq8 {
  border-color: inherit;
  text-align: center;
  vertical-align: middle;
  font-family: sans-serif;
}

.tg .tg-3j1n {
  background-color: #f7904c;
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: top;
  font-family: sans-serif;
}

.tg .tg-ioob {
  background-color: #f7904c;
  border-color: inherit;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  font-family: sans-serif;
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}

.tg .tg-0pky {
  border-color: inherit;
  text-align: left;
  vertical-align: top;
  font-family: sans-serif;
}

.tg .tg-nrix {
  text-align: center;
  vertical-align: middle;
}

.youtube-video iframe {
  aspect-ratio: 1/0.5;
  height: auto;
}

/* Seminar Page */

.seminar-button {
  width: fit-content !important;
  /* padding: 5px 8px; */
  background-color: #000;
  border-radius: 5px;
  padding: 5px 8px !important;
}

.seminar-button a {
  color: rgb(255, 255, 255);
  padding: 5px 8px !important;
}

.w-40 {
  width: 40% !important;
}

.capital-letter li::marker {
  content: "✓";
  color: #f7904c;
}

.capital-letter li {
  /* display: flex; */
}

.capital-letter li {
  padding-left: 5px;
}

.dropdown-content {
  left: 0 !important;
}

.react-international-phone-input-container {
  display: flex;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  font-size: .875rem;
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
}

.react-international-phone-input-container .react-international-phone-input {
  border: none !important;
}

.react-international-phone-country-selector-button {
  border: none !important;
}

/* @media (min-width:992px) { */
.event h1 {
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 10px;
}

.event h1::after {
  position: absolute;
  content: "";
  width: 100px !important;
  height: 5px;
  border-left: 110px solid #f7904c;
  border-right: 110px solid #f7904c;
  background-repeat: no-repeat;
  left: -50%;
  /* transform: translateX(-50%); */
  top: inherit;
  bottom: 0;
  animation: animationborder 2s linear infinite;
}

.form {
  justify-content: space-between;
}

.bannertext {
  position: absolute;
  top: 52%;
  left: 5%;
  transform: translateY(-50%);
}

.title h1::after {
  position: absolute;
  content: "";
  width: 220px;
  height: 5px;
  border-left: 110px solid #f7904c;
  border-right: 110px solid #f7904c;
  background-repeat: no-repeat;
  left: 0;
  top: 65px;
  animation: animationborder 2s linear infinite;
}

.title h2::after {
  position: absolute;
  content: "";
  width: 220px;
  height: 5px;
  border-left: 110px solid #f7904c;
  border-right: 110px solid #f7904c;
  background-repeat: no-repeat;
  left: 0;
  top: 50px;
  animation: animationborder 2s linear infinite;
}

.img_new_logo {
  position: relative;
}

.img_new_logo img {
  top: -40px;
  width: 150px !important;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.navbar li {
  /* margin-left: 23px !important; */
  margin-left: 20px !important;
  padding: 1rem 0;
}

.stall_button {
  padding: 12px 15px;
  background-color: #000;
  border-radius: 5px;
}

@media (min-width: 540px) and (max-width: 711.9px) {
  .homeline::after {
    top: 92px !important;
  }

  .banner-h1-text {
    font-size: 35px !important;
  }


}

@media (max-width: 568px) {
  .img_new_logo img {
    top: -20px;
    width: 100px !important;
    background: #fff;
    position: absolute;
    border-radius: 50%;
  }

  .img_logo {
    width: 150px !important;
  }

  .event h1::after {
    border-left: 80px solid #f7904c;
    border-right: 80px solid #f7904c;
    left: -100% !important;
  }

  .seminar-button {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 70% !important;
    text-align: center;
    z-index: 999;
  }

  .rounded-mob-2 {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }
}

/* } */
@media (min-width: 568px) {
  .rounded-lg-2 {
    border-radius: .25rem !important;
  }

  .mobile-version-width {
    background: #405e7a;
    max-width: 430px !important;
    margin: 0 auto;
    position: relative;
    padding: 0 !important;
    /* border: 1px solid #000; */
  }

  .mobile-version-width .bg-white-desk {
    min-height: 100vh;
    background: #fff;
  }

  .mobile-version-width .title h1,
  .mobile-version-width .title h2 {
    margin-bottom: 5px;
    font-size: 25px;
  }

  .mobile-version-width .title h1::after,
  .mobile-version-width .title h2::after {
    position: absolute;
    content: "";
    width: 110px;
    height: 5px;
    border-left: 55px solid #f7904c;
    border-right: 55px solid #f7904c;
    background-repeat: no-repeat;
    left: 0;
    top: 35px;
    animation: animationborder 2s linear infinite;
  }

  .card-canvas .offcanvas-bottom,
  .mobile-version-width .bottom-navbar,
  .mobile-version-width .splash-canvas .offcanvas-bottom {
    width: 430px !important;
    margin: 0 auto;
  }

  .card-canvas .offcanvas-bottom .offcanvas-body {
    margin-bottom: 20px !important;
  }

  .mobile-version-width .bottom-navbar {
    /* border: 1px solid #000; */
    border-top: 1px solid #e3e3e3;
    /* box-shadow: -5px 0 10px #e3e3e3; */
  }

  .mobile-version-width .qr-code-page {
    height: auto;
    padding-bottom: 6rem !important;
  }

  .mobile-version-width .id-card-holder .photo {
    display: block !important;
  }

  .mobile-version-width .id-card-holder .photo img {
    margin: 0 auto;
  }

  .mobile-version-width .comonseminarpadding {
    margin-bottom: 1rem !important;
  }

  .mobile-version-width .comonfontallseminar>.d-lg-flex {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .mobile-version-width .boxseminar1 {
    padding: 1rem;
  }

  .mobile-version-width .comonfontallseminar>.d-lg-flex .speaker-img {
    margin-bottom: 1rem !important;
  }

  .mobile-version-width .comonfontallseminar>.d-lg-flex .ps-lg-3 {
    padding: 0 !important;
  }

  .mobile-version-width .apexcharts-canvas,
  .mobile-version-width .apexcharts-canvas svg {
    width: 390px !important;
  }
}

.send-message-textarea {
  border: 1px solid !important;
}



/* new event css */
.banner-added-new-text h3 {
  line-height: 35px;
}



/* header */
.list-style {
  list-style: disc !important;
  margin: 0;
}

.list-style li {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding-left: 2rem;
}

.list-style li a {
  padding: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}

.list-style li a::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 52%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000;
}



.list-style li:hover {
  background: #f7904c !important;
}

.list-style li a {
  padding-left: 0;
  margin-left: 0 !important;
}

/* year buisness */
.business {
  border: 3px solid #f7904c;
  border-radius: 10px;
}

.business .heading-text {
  width: fit-content;
  background: #fff;
  margin: 0 auto;
  transform: translateY(-30px);
  padding: 10px 1rem;
}

.business .heading-text h2 {
  /* font-size: 2rem; */
}

.days-three {
  font-size: 3rem;
}

.title-center h1::after {
  left: 40%;
  transform: translateX(-40%);
}

@media (max-width:568px) {
  .days-three {
    display: block;
    font-size: 2.6rem;
  }

  .title-center h1::after {
    left: 0;
    transform: translateX(0);
  }
}

@media (max-width:420px) {
  .business .heading-text h2 {
    font-size: 1.5rem;
  }

  .days-three {
    display: block;
    font-size: 2rem;
  }
}