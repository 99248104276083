@media (min-width:992px) {
	.phn-view-below-banner-bookbtn {
		display: none !important;
	}

	.client-logo-bottom {
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: none;
		padding: none;
		border-radius: none;
	}
}

@media (max-width:992px) {
	.client-logo-bottom {
		border-top: 3px solid #f7904c;
		border-right: 1px solid #f7904c;
		border-left: 1px solid #f7904c;
		border-bottom: 1px solid #f7904c;
		padding: 10px;
		border-radius: 10px;
	}
}

.book-stall-btn-banner-animation {
	position: relative;
	padding: 10px 4px;
	color: #fff;
	text-decoration: none;
	overflow: hidden;
	background: rgba(255, 255, 255, 0.23);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(6.1px);
	-webkit-backdrop-filter: blur(6.1px);
}

.book-stall-btn-banner-animation span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(to right, #fff, #f7904c);
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.book-stall-btn-banner-animation span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to bottom, #fff, #f7904c);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}

.book-stall-btn-banner-animation span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(to left, #fff, #f7904c);
	animation: animate3 2s linear infinite;
}

@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

.book-stall-btn-banner-animation span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to top, #fff, #f7904c);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(-100%);
	}
}


.heart {
	animation: beat .9s infinite alternate;
	transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
	to {
		transform: scale(1.1);
	}
}

.stall_button-banner-phnview {
	background: #f7904c !important;
	color: #fff !important;
}

.lax {
	position: absolute;
	bottom: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: auto;
	width: 100%;
}

.lax ul {
	gap: 45px;
}

.lax1 {
	bottom: 25px;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	margin: auto;
	width: 100%;
}

.lax1 ul {
	gap: 45px;
}

.book-stall-btn-bottom-animation {
	position: relative;
	padding: 6px;
	color: #fff;
	text-decoration: none;
	overflow: hidden;
}

.book-stall-btn-bottom-animation span:nth-child(1) {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(to right, #fff, #f7904c);
	animation: animate1 2s linear infinite;
}

@keyframes animate1 {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.book-stall-btn-bottom-animation span:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to bottom, #fff, #f7904c);
	animation: animate2 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate2 {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}

.book-stall-btn-bottom-animation span:nth-child(3) {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 4px;
	background: linear-gradient(to left, #fff, #f7904c);
	animation: animate3 2s linear infinite;
}

.button .stall_button {
	padding: 10px 15px !important;
	font-weight: 600;
}

.button li a {
	background-color: #f7904c !important;
}


@keyframes animate3 {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

.book-stall-btn-bottom-animation span:nth-child(4) {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 3px;
	background: linear-gradient(to top, #fff, #f7904c);
	animation: animate4 2s linear infinite;
	animation-delay: 1s;
}

@keyframes animate4 {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(-100%);
	}
}

@media (max-width: 568px) {
	.fixed-vistor {
		position: fixed;
		left: 50%;
		bottom: 1rem;
		transform: translateX(-50%);
		z-index: 99;
		width: 80%;
	}

	.fixed-vistor a {
		width: 100%;
		display: block;
		text-align: center;
	}

	.call_icon {
		bottom: 100px;
	}
}

@media (max-width: 450px) and (min-width :391px) {
	.bannertext-lax {
		position: absolute;
		top: 48% !important;
		left: 3%;
		transform: translateY(-50%);
		width: 95%;
	}

	.banner-h1-text {
		font-size: 26px !important;
	}

	.banner-p-text {
		font-size: 15px !important;
		color: #fff;
		margin-top: 0px !important;
		text-align: left;
	}

	.lax {
		position: absolute;
		bottom: 55px !important;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: auto;
		width: 100%;
	}

	.heading-text h2 {
		font-size: 30px;
		line-height: 45px;
	}

	.nav-uppar {
		height: 7vh;
	}

	.bannerimg2 {
		height: 25vh;
		object-fit: cover;
	}

	.img_logo {
		width: 110px;
		margin-left: -20px;
	}

	.title h1::after {
		top: 35px !important;
	}

	.title-center h1::after {
		top: 65px !important;
		left: 20%;
	}

	.aboutusection h4 {
		font-size: 17px;
	}

	.icon-image img {
		width: 40%;
	}

}

@media (max-width: 390px) and (min-width :200px) {
	.bannertext-lax {
		position: absolute;
		top: 48% !important;
		left: 3%;
		transform: translateY(-50%);
		width: 95%;
	}

	.bannerimg2 {
		height: 25vh;
		object-fit: cover;
	}

	.heading-text h2 {
		font-size: 30px;
		line-height: 45px;
	}



	.icon-image img {
		width: 40%;
	}

	.nav-uppar {
		height: 8vh !important;
	}

	.img_logo {
		width: 110px;
		margin-left: -20px;
	}

	.banner-h1-text {
		font-size: 25px !important;
	}

	.banner-bold-text {
		font-size: 17px !important;
	}

	.title h1::after {
		top: 65px !important;
	}



	.aboutusection h4 {
		font-size: 17px;
	}

	.banner-p-text {
		font-size: 14px !important;
		color: #fff;
		margin-top: 0px !important;
		text-align: left;
	}

	.lax {
		position: absolute;
		bottom: 55px !important;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: auto;
		width: 100%;
	}

	.banner-btn-phn-view-two {
		font-size: 13px !important;
	}

	.lax ul {
		gap: 25px !important;
	}
}

@media (max-width:365px) {
	.title-center h1::after {
		top: 95px !important;
	}
}

@media (min-width:691px) and (max-width:800px) {
	.banner-h1-text {
		font-size: 35px !important;
	}

	.banner-bold-text {
		font-size: 25px !important;
	}

	.banner-p-text {
		margin-top: 0px !important;
		padding-top: 0px !important;
	}

	/* .bannertext-lax {
		top: 50% !important;
	} */
}

@media (max-width:540.9px) and (min-width:490px) {
	.banner-h1-text {
		font-size: 30px !important;
	}

	.banner-bold-text {
		line-height: 2rem !important;
	}

	.banner-bold-text b {
		font-size: 25px !important;
		line-height: 2rem !important;
	}

	.lax {
		position: absolute;
		bottom: 45px !important;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: auto;
		width: 100%;
	}

	.banner-p-text {
		font-size: 20px !important;
		padding-top: 0px !important;
		margin-top: 0px !important;
	}

}

@media (max-width:490.9px) and (min-width:300px) {
	.banner-h1-text {
		font-size: 25px !important;
	}

	.banner-bold-text {
		line-height: 2rem !important;
	}

	.banner-bold-text b {
		font-size: 20px !important;
		line-height: 2rem !important;
	}

	.lax {
		position: absolute;
		bottom: 45px !important;
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		margin: auto;
		width: 100%;
	}

	.banner-p-text {
		font-size: 16px !important;
		padding-top: 0px !important;
		margin-top: 0px !important;
	}

}

@media (min-width:992px) {
	.banner-half-text {
		display: block !important;
	}
}

@media (min-width:451px) and (max-width:490px) {
	.bannertext-lax {
		top: 58% !important;
	}
}